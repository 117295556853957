import React, { useState } from 'react';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
// others
import useSignInRequest from './useSignInRequest';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
}));

function SignInPage() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const { loading: disabled, error, onSubmit } = useSignInRequest();

  const handleSubmit = event => {
    event.preventDefault();
    return onSubmit(event, { username, password, rememberMe: remember });
  };

  return (
    <Container className={classes.root} component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <img
          className={classes.logo}
          src={process.env.PUBLIC_URL + 'images/icons-64.png'}
          alt="logo"
          width={64}
          height={64}
        />
        <Typography component="h1" variant="h5">
          Sentinel
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            error={!!error}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={disabled}
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={event => setUsername(event.target.value)}
          />
          <TextField
            error={!!error}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={disabled}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={remember}
                value="remember"
                color="primary"
                onChange={event => setRemember(event.target.checked)}
              />
            }
            label="Remember me"
          />
          {error && <Typography color="error">{error.message}</Typography>}
          <Button
            type="submit"
            fullWidth
            disabled={disabled}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Link color="inherit" href="#" variant="body2">
            Forgot password?
          </Link>
        </form>
      </Paper>
    </Container>
  );
}

export default SignInPage;
