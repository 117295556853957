import { useState } from 'react';
import { SIGN_IN } from './operations';
import useAuth from 'backend/useAuth';

const initialRequestStatus = {
  loading: false,
  error: null,
};

function useSignInRequest() {
  const { setToken } = useAuth();
  const [requestStatus, setRequestStatus] = useState(initialRequestStatus);

  const onSubmit = (_, { username, password, rememberMe }) => {
    setRequestStatus({ ...initialRequestStatus, loading: true });

    const variables = {
      login: username,
      password,
      rememberMe,
    };
    return fetch(process.env.REACT_APP_BACKEND, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: SIGN_IN, variables }),
    })
      .then(res => res.json())
      .then(fetchResult => {
        if (fetchResult?.errors) {
          setRequestStatus({
            ...initialRequestStatus,
            error: fetchResult.errors[0],
          });
        } else {
          setRequestStatus(initialRequestStatus);
          return setToken(fetchResult?.data?.login?.token);
        }
      })
      .catch(error => setRequestStatus({ ...initialRequestStatus, error }));
  };

  const { loading, error } = requestStatus;

  return {
    loading,
    error,
    onSubmit,
  };
}

export default useSignInRequest;
