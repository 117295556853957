import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core/styles
import createMuiTheme from '@material-ui/core/styles/createTheme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import palette from './theme/palette';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';

const buildAppTheme = () => {
  return createMuiTheme({
    palette,
    mixins: {
      sidebarItems: {
        minWidth: 247,
        maxWidth: 352,
      },
      toolbar: {
        height: 37,
      },
    },
    overrides: {
      MuiTabs: {
        root: {
          minHeight: 37,
        },
      },
      MuiTab: {
        root: {
          minHeight: 37,
        },
      },
    },
  });
};

function AppTheme({ children }) {
  const theme = buildAppTheme();
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

AppTheme.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppTheme;
