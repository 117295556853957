import React, { lazy, Suspense } from 'react';
// react-router-dom
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
// hooks
import useAuth, { isTokenValid } from 'backend/useAuth';
// layouts
import SignInPage from 'layouts/SignIn';
const HomePage = lazy(() => import('layouts/Home'));

function AppRouter() {
  const { token } = useAuth();
  const loggedIn = token && isTokenValid(token);

  return (
    <Router>
      <Switch>
        <Route path="/login" exact>
          {loggedIn && <Redirect to="/" />}
          <SignInPage />
        </Route>
        <Route>
          {!loggedIn && <Redirect to="/login" />}
          <Suspense fallback={null}>
            <HomePage />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;
