import { useCookies } from 'react-cookie';
import jwt from 'jsonwebtoken';

const KEY_TOKEN = 'SENTINEL_TOKEN';

const decodeUserFromToken = token => jwt.decode(token)?.user;

const getTokenExpireFromNow = token => {
  const { exp } = jwt.decode(token);
  const now = Math.round(new Date().getTime() / 1000);
  return exp - now;
};

export const isTokenValid = token => getTokenExpireFromNow(token) > 0;

export default function useAuth() {
  const [cookies, setCookie, removeCookie] = useCookies([KEY_TOKEN]);
  const token = cookies[KEY_TOKEN];

  const setToken = token => {
    const options = {
      path: '/',
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'strict',
      maxAge: getTokenExpireFromNow(token),
    };
    setCookie(KEY_TOKEN, token, options);
  };

  return {
    token,
    user: decodeUserFromToken(token),
    setToken,
    removeToken: (...args) => removeCookie(KEY_TOKEN, ...args),
  };
}
