import React from 'react';
import AppTheme from './AppTheme';
import AppRouter from './routes/AppRouter';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const App = () => (
  <AppTheme>
    <AppRouter />
  </AppTheme>
);

export default App;
